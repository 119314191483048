import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'rnd-helmet';
import { injectIntl } from "react-intl";
import { useDataLayer, pushJobAlertRemovedEvent } from "dataLayerHelper";
import FormContext from "formContext";
import Form from 'form';
import axios from "axios";
import GetRouteByName from 'getRouteByName';
import Button from "@FormElements/Button";
import Modal from "@Components/Modal";
import SubMenu from "@Components/SubMenu";
import Loading from "@Components/Loading";
import DashboardItem from "@Components/DashboardItem";
import Applications from "@Components/Applications";
import TextWithLink from "@Components/TextWithLink";
import JobCardList from "@Components/JobCardList";
import JobAlertList from "@Components/JobAlertList";
import ConfirmationModal from "@Components/ConfirmationModal";
import useJobCardHelper from 'jobCardHelper';
import DashboardJobAlerts from '@Components/DashboardJobAlerts';
import scrollTo from 'scrollTo';
import getGreetingMessage from 'getGreetingMessage';
import useDateHelper from 'dateHelper';
import Logger from '@ffw/logger-lib';
import Link from "@Components/Link";

const reactAppCountry = process.env.REACT_APP_COUNTRY;

function Dashboard({intl, staticContext, match}) {
  const { locale } = intl;
  const {
    additionalData,
    setAdditionalData,
    formName,
    setFormName,
    formData,
    setFormData,
    currentStep,
    getFormSchema,
    handleErrors,
    modalOpen,
    setModalOpen,
    editedItemKey,
    setEditedItemKey,
    setErrors,
    timesRendered,
    triggerRerender,
    handleChange,
    showToast,
    setToastMessage
  } = useContext(FormContext);
  const {user, jobApplications, openApplications, recommendedJobs, favouriteJobs, jobAlerts} = additionalData;

  const favouritesPageUrl = GetRouteByName('favourites') ? GetRouteByName('favourites').url : '';
  const applicationsPageUrl = GetRouteByName('my-applications') ? GetRouteByName('my-applications').url : '';
  const recommendedJobsUrl = recommendedJobs && Object.keys(recommendedJobs.jobSuggestions).length ? recommendedJobs.searchUrl : GetRouteByName('jobs') ? GetRouteByName('jobs').url : '';
  const jobPreferencesUrl = GetRouteByName('job-preferences') ? GetRouteByName('job-preferences').url : '';
  const brightFitUrl = '/api/my-randstad/brightfit-redirect';
  const { formatJobRawFeed } = useJobCardHelper();
  const { createNewDate, dateHasExpired } = useDateHelper();
  const [applicationStatuses, setApplicationStatuses] = useState(undefined);

  const monthsToExpire = 3;

  // Generate dataLayer for the page.
  useDataLayer(locale, staticContext);

  useEffect(() => {
    if (window.__ROUTE_DATA__) {
      delete window.__ROUTE_DATA__;
    }
    else {
      Promise.all([
        axios.get(`${process.env.REACT_APP_API_PREFIX}/user`),
        axios.get(`${process.env.REACT_APP_API_PREFIX}/user/job-applications`),
        axios.get(`${process.env.REACT_APP_API_PREFIX}/user/open-applications`),
        axios.get(`${process.env.REACT_APP_API_PREFIX}/user/saved-jobs`),
        axios.get(`${process.env.REACT_APP_API_PREFIX}/get-recommended-jobs`),
        axios.get(`${process.env.REACT_APP_API_PREFIX}/job-alerts`),
      ])
        .then( ([user, jobApplications, openApplications, favouriteJobs, recommendedJobs, jobAlerts]) => {
          setAdditionalData(prevState => ({
            ...prevState,
            user: user.data,
            jobApplications: jobApplications.data,
            openApplications: openApplications.data,
            favouriteJobs: favouriteJobs.data,
            recommendedJobs: recommendedJobs.data,
            jobAlerts: jobAlerts.data
            })
          )
      });
    }
  }, [timesRendered]);

  useEffect(() => {
    // We set application statuses only for PT (Portugal) to use for rejected appl.
    if (!process.env.REACT_APP_REJECTED_APPLICATIONS) return;

    if (user) {
      axios.get(`${process.env.REACT_APP_API_PREFIX}/application-statuses-list?userId=${user.userId}`)
        .then(response => {
        if (response.data && response.data.applications) {
          setApplicationStatuses(response.data.applications);
        }
        else {
          setApplicationStatuses([]);
        }
      }).catch((error) => {
        setApplicationStatuses([]);
      });
    }
  }, []);

  if (
    !user ||
    !jobAlerts ||
    !jobApplications ||
    (process.env.REACT_APP_SAVEDJOBS_ITEM === "true" && !favouriteJobs) ||
    (process.env.REACT_APP_OPEN_APPLICATIONS === "true" && !openApplications) ||
    (process.env.REACT_APP_REJECTED_APPLICATIONS === "true" && !applicationStatuses)
  ) {
    return <Loading />;
  }

  const parseApplications = (applications, type = 'job', getExpiredItems = false) => {
    applications && applications.map(item => {
      item.created_timestamp = (item.created_timestamp ? item.created_timestamp : item.timestamp) || item.created;
      const createdDate = createNewDate(item.created_timestamp);
      const expirationDate = new Date(createdDate.setMonth(createdDate.getMonth() + monthsToExpire));
      const expired = dateHasExpired(new Date(), expirationDate);

      item.applicationExpired = expired;
      item.applicationCardType = type;

      return expired;
    });

    return applications.filter(
      item => getExpiredItems ? item.applicationExpired : !item.applicationExpired
    );
  }

  let getOpenApplications = [];

  if (process.env.REACT_APP_OPEN_APPLICATIONS === "true") {
    getOpenApplications = openApplications && openApplications.count > 0 ?
    parseApplications(openApplications.applications, 'open', false) : [];
  }

  const getJobApplications = jobApplications && jobApplications.count > 0 ?
    parseApplications(jobApplications.applications, 'job', false) : [];
  const applications = {
    applications: [...getOpenApplications, ...getJobApplications]
  };
  applications.count = applications.applications.length;

  // Sort by created_timestamp.
  if (applications && applications.applications) {
    applications.applications.sort(function (a, b) {
      return new Date(b.created_timestamp) - new Date(a.created_timestamp);
    });
  }
  // Check which active/open applications are already rejected and remove them.
  let rejectedApplications = [];
  if (process.env.REACT_APP_REJECTED_APPLICATIONS === "true") {
    applications && applications.applications.forEach((application) => {
      const applicationId = application.details && application.details.applicationId;

      if (applicationId) {
        let rejected = false;

        // Get rejected value (true/false).
        if (applicationStatuses) {
          applicationStatuses.find((atsApplication) => {
            if (atsApplication.applicationId === applicationId) {
              rejected = atsApplication.rejected;
            }
          });
        }

        if (rejected && rejected === 'true') {
          rejectedApplications.push(application);
        }
      }
    })
  }

  const rejectedApplicationsToDeleteSet = new Set(rejectedApplications);

  const activeApplications = applications.applications.filter((name) => {
    return !rejectedApplicationsToDeleteSet.has(name);
  });

  const renderLoginHoursButton = () => {
    let linkPath = `${process.env.REACT_APP_API_PREFIX}/login-Inteliplan`;

    return <div id="login_your_hours" className="block empty-message l:pb-l pb-l">
      <div className=" block__wrapper--stacked wrapper">
        <div className="block__header block__header--split">
          <h2 className="title--s pb-xs l:pb-none l:text-title-m">{intl.formatMessage({id: 'Dashboard.RegisterHours.Title'})}</h2>
        </div>

        <p className='content-block__description mt-s'>{intl.formatMessage({id: 'Dashboard.RegisterHours.Description'})}</p>
        <Link className="button button--m mt-s" to={linkPath} target='_blank'>{intl.formatMessage({id: 'Dashboard.RegisterHours.LinkText'})}</Link>
      </div>
    </div>;
  }

  const renderMissingContent = (type = 'jobAlerts') => {
    const settings = {
      text:
        type === "recommendedJobs"
          ? "RecommendedJobs.NoJobs.Text"
          : type === "favoriteJobs"
          ? "Favourites.NoFavourites.Text"
          : "JobAlerts.NoAlerts.Text",
      link: 'jobs',
      linkText: 'Utils.JobSearch',
      linkClasses: 'button button--m mt-s',
      divClasses: '',
      pClasses: type === 'recommendedJobs' ? 'content-block__description mt-s' : '',
      isExternal: true
    }

    return <TextWithLink settings={settings}/>;
  }

  const renderBrightfitButton = () => {
    const settings = {
      text: "Brightfit.Section.Title",
      link: brightFitUrl,
      url: brightFitUrl,
      linkText: 'Brightfit.Button.Title',
      linkClasses: 'button button--m mt-s',
      divClasses: '',
      isExternal: true
    }

    return <TextWithLink settings={settings}/>;
  }

  const renderFavouriteJobs = () => {
    favouriteJobs.jobs.forEach(favJob => {
      favJob.showDate = 'jobPostDate';
    });
    return (
      <div className="block__content block__content--full-on-s block__content--full-on-m">
        <JobCardList
          isSlider={true}
          offsetPerPage={3}
          showMoreVisibility={false}
          {...favouriteJobs}/>
      </div>)
  }

  const handleDeleteJobAlert = (index) => {
    const item = jobAlerts.alerts[index];
    axios.delete(`${process.env.REACT_APP_API_PREFIX}/user/job-alerts/${item.key}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          pushJobAlertRemovedEvent();
          setModalOpen(false);
          setFormData([]);
          setEditedItemKey('');
          setErrors();
          triggerRerender();
        }
      })
      .catch(error => {
        handleErrors(error.response.data);
      });
  }

  const handleSubmit = event => {
    event.preventDefault();

    let submitApiPath, method;
    const data = formData[currentStep];
    // Filters can't be changed.
    delete data['searchFilters'];

    if (formName === 'job-alert') {
      if (editedItemKey) {
        method = "put";
        submitApiPath = `/user/job-alerts/${editedItemKey}`;
      }
    }

    if (!submitApiPath) {
      Logger.info(`Unable to update data. Missing API path for ${formName} form`, `dashboard/${reactAppCountry}`);
      return null;
    }

    axios({
      method: method,
      url: `${process.env.REACT_APP_API_PREFIX}${submitApiPath}`,
      data: data
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          setModalOpen(false);
          setFormData([]);
          setErrors();
          setEditedItemKey('');
          setTimeout(() => {
            const message = intl.formatMessage({id: 'Profile.AccountUpdated.Toast.SuccessMessage'});
            setToastMessage(message);
            showToast();
          }, 500);
          triggerRerender();
        }
      })
      .catch(error => {
        handleErrors(error.response.data);
      });
  };

  const modalFooter = () => (
    <Button onClick={handleSubmit} className="button button--m button--filled button--full-width" settings={{text: getFormSchema(formName)[currentStep].modalButton}} />
  );

  const onCloseModal = () => {
    setModalOpen(false);
    setFormName('');
    setFormData([]);
    setEditedItemKey('');
  };

  const renderModal = () => {
    if (!modalOpen) {
      return '';
    }

    if (!formName) {
      const deletedItemIndex = formData[currentStep]['index'];
      const title = formData[currentStep]['searchName'] || '';
      return (
        <ConfirmationModal
          title={intl.formatMessage({id: 'JobAlerts.JobAlert.Delete.Modal.Title'})}
          buttonFilled={false}
          handleConfirmation={e => handleDeleteJobAlert(deletedItemIndex)}
          footerButtonText={intl.formatMessage({id: 'JobAlerts.JobAlert.Delete.Modal.Footer.Button.Text'})}
        >
          <p>{intl.formatMessage({id: 'JobAlerts.JobAlert.Delete.Modal.Text'}, {title: `'${title}'`})}</p>
        </ConfirmationModal>
      );
    }

    return (
      <Modal
        title={intl.formatMessage({id: getFormSchema(formName)[currentStep].modalTitle})}
        onClose={onCloseModal}
        footer={modalFooter()}
        footerDivider={true}
      >
        <Form name={formName} handleChange={handleChange} state={formData[currentStep]} handleSubmit={handleSubmit}/>
      </Modal>
    );
  };

  const renderRecommendedJobs = () => {
    const jobsSuggestions = recommendedJobs.jobSuggestions;

    // Prepare raw job feed for printing.
    const jobsFormatted = {}
    for (let key in jobsSuggestions) {
      if (jobsSuggestions.hasOwnProperty(key)) {
        jobsFormatted[key] = formatJobRawFeed(jobsSuggestions[key]);
        jobsFormatted[key].showDate = 'jobPostDate';
      }
    }

    // Prepare the array
    const jobsArray = Object.keys(jobsFormatted).map(e => jobsFormatted[e]);

    const jobsRenderable = {
      count: Object.keys(jobsFormatted).length,
      jobs: jobsArray
    }

    return <JobCardList
      offsetPerPage={3}
      isRecommendedJob={true}
      {...jobsRenderable}/>
  }

  const shouldRenderFavoriteJobs = favouriteJobs && favouriteJobs.hasOwnProperty('count') && favouriteJobs.count;
  const shouldRenderRecommendedJobs = recommendedJobs && Object.keys(recommendedJobs.jobSuggestions).length;

  const GreetingNames = () => {
    if (user.first_name || user.last_name) {
      return <>{user.first_name} {user.last_name}</>;
    }
    else if (user.FirstName || user.SurName) {
      return <>{user.FirstName} {user.SurName}</>;
    }
    else if (process.env.REACT_APP_ATOME_DATA && user?.atomeData?.FirstName) {
      return <>{user.atomeData.FirstName.toLowerCase()}</>;
    }
    return '';
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'Menu.Dashboard'})} | {intl.formatMessage({id: 'Breadcrumb.Dashboard'})}</title>
        <header className="header bg-variant-white header--s header--welcome"/>
      </Helmet>
      <SubMenu />
      <div className="block--s">
        <div className="block__wrapper block__wrapper--stacked wrapper pt-m l:pt-l">
          <div className="my-environment-container">
            <p className="content-block__eyebrow">
              <span>{intl.formatMessage({id: getGreetingMessage( { country: reactAppCountry })})}</span>
            </p>
            <h1 className="content-block__title"><GreetingNames /></h1>

          </div>
          <div className="my-environment-container">
            <div className="dashboard-cards mt-m">
              <div className="dashboard-card cards__item p-s l:py-s l:px-m">
                <div className="dashboard-card--top mb-xxs">
                  <a href="#" className="cards__link" tabIndex="0">
                    {intl.formatMessage({id: 'Applications.MyApplications.Title'})}
                    <span className="make-entire-card-clickable" onClick={(e) => {
                      e.preventDefault();
                      scrollTo('#applications', "smooth", 0);
                    }}>
                    </span>
                  </a>
                </div>
                <div className="dashboard-card--middle l:my-xs">
                  {activeApplications.length || 0}
                </div>
                <div className="dashboard-card--bottom mt-xxs text-brand-secondary-tint-60">
                  {(!activeApplications.length) ? intl.formatMessage({id: 'Applications.NoApplications.Text'}) : null}
                </div>
              </div>

              {process.env.REACT_APP_SAVEDJOBS_ITEM === "true" && (
                <div className="dashboard-card cards__item p-s l:py-s l:px-m">
                  <div className="dashboard-card--top mb-xxs">
                    <a href="#" className="cards__link" tabIndex="0">
                      {intl.formatMessage({ id: "Favourites.Header.Title" })}
                      <span
                        className="make-entire-card-clickable"
                        onClick={(e) => {
                          e.preventDefault();
                          scrollTo("#saved-jobs", "smooth", 0);
                        }}
                      ></span>
                    </a>
                  </div>
                  <div className="dashboard-card--middle l:my-xs">
                    {favouriteJobs && favouriteJobs.count || 0}
                  </div>
                  <div className="dashboard-card--bottom mt-xxs text-brand-secondary-tint-60">
                    {favouriteJobs && !favouriteJobs.count
                      ? intl.formatMessage({
                          id: "Favourites.NoFavourites.Text",
                        })
                      : null}
                  </div>
                </div>
              )}

              {process.env.REACT_APP_RECOMMENDEDJOBS_ITEM === "true" && (
                <div className="dashboard-card cards__item p-s l:py-s l:px-m">
                  <div className="dashboard-card--top mb-xxs">
                    <a href="#" className="cards__link" tabIndex="0">
                      {intl.formatMessage({ id: "RecommendedJobs.Title" })}
                      <span
                        className="make-entire-card-clickable"
                        onClick={(e) => {
                          e.preventDefault();
                          scrollTo("#recommended-jobs", "smooth", 0);
                        }}
                      ></span>
                    </a>
                  </div>
                  <div className="dashboard-card--middle l:my-xs">
                    {shouldRenderRecommendedJobs || 0}
                  </div>
                  <div className="dashboard-card--bottom mt-xxs text-brand-secondary-tint-60">
                    {!shouldRenderRecommendedJobs
                      ? intl.formatMessage({
                          id: "RecommendedJobs.NoJobs.Text",
                        })
                      : null}
                  </div>
                </div>
              )}

              {process.env.REACT_APP_JOBALERTS_ITEM === "true" && (
                <div className="dashboard-card cards__item p-s l:py-s l:px-m">
                  <div className="dashboard-card--top mb-xxs">
                    <a href="#" className="cards__link" tabIndex="0">
                      {intl.formatMessage({ id: "JobAlerts.Card.Title" })}
                      <span
                        className="make-entire-card-clickable"
                        onClick={(e) => {
                          e.preventDefault();
                          scrollTo("#job-alerts", "smooth", 0);
                        }}
                      ></span>
                    </a>
                  </div>
                  <div className="dashboard-card--middle l:my-xs">
                    {jobAlerts.count || 0}
                  </div>
                  <div className="dashboard-card--bottom mt-xxs text-brand-secondary-tint-60">
                    {!jobAlerts.count
                      ? intl.formatMessage({ id: "JobAlerts.NoJobAlerts.Text" })
                      : null}
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
      <div style={{overflow: 'hidden'}}>
        {process.env.REACT_APP_COUNTRY === "dk" && (renderLoginHoursButton())}
        <DashboardItem
          id="applications"
          bg={activeApplications && activeApplications.length ? "block--s pb-xs" : "block empty-message l:pb-l"}
          title={intl.formatMessage({id: 'Applications.MyApplications.Title'})}
          linkPath={(activeApplications && activeApplications.length && activeApplications.length >= 3) && applicationsPageUrl}
          linkText={(activeApplications && activeApplications.length && activeApplications.length >= 3) && intl.formatMessage({id: 'Utils.Applications.SeeAll'})}
        >
          <Applications
            jobApplications={activeApplications}
            isSlider={true}
          />
        </DashboardItem>

        {process.env.REACT_APP_BRIGHTFIT === "true" && (
          <DashboardItem
            id="brightfit"
            title={intl.formatMessage({id: 'Brightfit.Header.Title'})}
          >
            {renderBrightfitButton()}
          </DashboardItem>
        )}

        {process.env.REACT_APP_SAVEDJOBS_ITEM === "true" && (
          <DashboardItem
            id="saved-jobs"
            bg={shouldRenderFavoriteJobs ? "block--s pb-xs" : "block empty-message l:pb-l"}
            title={intl.formatMessage({id: 'Favourites.Header.Title'})}
            linkPath={shouldRenderFavoriteJobs && favouritesPageUrl}
            linkText={shouldRenderFavoriteJobs && intl.formatMessage({id: 'Utils.FavouriteJobs.SeeAll'})}
            blockWrapper={shouldRenderFavoriteJobs}
          >
            {shouldRenderFavoriteJobs ? renderFavouriteJobs() : renderMissingContent('favoriteJobs')}
          </DashboardItem>
        )}

        {process.env.REACT_APP_RECOMMENDEDJOBS_ITEM === "true" && (
          <DashboardItem
            id="recommended-jobs"
            title={intl.formatMessage({id: 'RecommendedJobs.Title'})}
            tooltip={intl.formatMessage({id: 'RecommendedJobs.Tooltip'})}
            linkPath={recommendedJobsUrl}
            linkText={intl.formatMessage({id: 'Utils.RecommendedJobs.SeeAll'})}
            isExternal={true}
            blockDescription={intl.formatMessage({id: 'RecommendedJobs.Description'})}
            blockDescriptionLinkText={intl.formatMessage({id: 'RecommendedJobs.Description.LinkText'})}
            blockDescriptionLinkUrl={jobPreferencesUrl}
            >
            {shouldRenderRecommendedJobs ? (
              <div
                className={`block__content block__content--full-on-s block__content--full-on-m`}
              >
                {renderRecommendedJobs()}
              </div>
            ) : (
              renderMissingContent('recommendedJobs')
            )}
          </DashboardItem>
        )}

        <DashboardJobAlerts
          id={"job-alerts"}
          leftLinkText={intl.formatMessage({id: 'JobAlerts.BlockControl.Label'})}
          leftLinkPath="#">
          {jobAlerts && jobAlerts.count ? <JobAlertList jobAlerts={jobAlerts} /> : renderMissingContent('jobAlerts')}
        </DashboardJobAlerts>
      </div>
      {renderModal()}
    </>
  )
}

export default injectIntl(Dashboard);
